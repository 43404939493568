input {
  height: 2em !important;
}

#dev-tools {
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 1.2em;
  height: 4em;
  width: 4em;
  transition: all 0.3s;
  border-radius: 6px 6px 0 0;
  z-index: 1000;
}

#dev-tools.impersonating {
  background-color: rgba(0, 90, 173, 0.7);
  animation: blinker 1s linear infinite;
}

#dev-tools.alert {
  background-color: gold;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

#dev-tools:hover {
  height: 30vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
}

#dev-tools.alert:hover {
  animation: none;
}
#dev-tools.impersonating:hover {
  animation: none;
}

#dev-tools .tools {
  display: none;
}

#dev-tools:hover .tools {
  display: block;
}
