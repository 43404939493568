@font-face {
  font-family: 'Mark Pro'; /* regular */
  src: url('markw01r.woff2') format('woff2'),
    url('markw01r.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark Pro'; /* bold */
  src: url('markw01b.woff2') format('woff2'),
    url('markw01b.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap');
